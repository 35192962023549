
import { defineComponent } from 'vue'
import useDealerVendorCodeSet from "@/hooks/payment/EntitySiPayout/useDealerVendorCodeSet";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
// import Pagination from "@/components/Pagination.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import UploadDetail from '@/views/Payment/EntitySiPayout/Vendor/uploadDetail.vue'
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
type Key = ColumnProps['key'];
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-458
export default defineComponent({
  name: 'VehicleNameSet',
  components: {
    UploadDetail,
    // Pagination,
    TableWrapper,
    EntitySelector
  },
  props: {},
  setup() {
    const {
      searchParams,
      columnsData, tableData, doSearch, doReset, pageChange, sizeChange, pageSize, pageNumber, total,
      isUploadVisible, exportClick, uploadClick, closeUpload, refreshTable, entityRef
    } = useDealerVendorCodeSet()
    // 获取Entity下拉列表
    doSearch();
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }
    return {
      searchParams,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
      entityRef
    }
  }
})
