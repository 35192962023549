import axios from 'axios'
import { AxiosRequestConfig } from '@/API/types'
export default (options: AxiosRequestConfig) => { 
    const { url, method = 'get', params, fileName } = options;
    const config: any = {
        url: url,
        method,
        responseType: "blob",
    };
    if (method === "get") {
        config["params"] = params;
    } else {
        config["data"] = params;
    }
    return new Promise((resolve, reject) => {
        axios(config).then((res: any) => {
            // 文件格式
            const blob = new Blob([res.data], { type: 'application/vnd-excel; char-set=UTF-8' })
            const downloadElement = document.createElement('a')
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            // 下载后文件名
            downloadElement.download = !fileName ? Date.now().toString() : fileName
            document.body.appendChild(downloadElement)
            downloadElement.click() 
            document.body.removeChild(downloadElement)
            // 释放掉blob对象
            window.URL.revokeObjectURL(href)
            resolve(res)
        }).catch(function (error: string) {
            console.log(error)
            return reject(error)
        })
    })
}